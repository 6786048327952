/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    h3: "h3",
    h4: "h4",
    a: "a",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "2022 List of Hosting Solutions"), "\n", React.createElement(_components.p, null, "This is a reference list of hosting options in 2022 prompted by the issues with the darling Heroku(Sales Force)."), "\n", React.createElement(_components.h3, null, "TL:DR"), "\n", React.createElement(_components.h4, null, "Opinion"), "\n", React.createElement(_components.p, null, "Recently Heroku(Sales Force) made many business decisions that have fractured the fragile relationship with the development community. In short two huge issues first the ", React.createElement(_components.a, {
    href: "https://status.heroku.com/incidents/2413"
  }, "security breach April 13"), " and how it was handled and the removal of the free tiers for ", React.createElement(_components.a, {
    href: "https://blog.heroku.com/next-chapter"
  }, "\"Heroku's Next Chapert"), ". Any student, aspiring developer, maker, solopreneur, entrepreneur and small business owner knows development is expensive in time, effort, and tools and the payout is not always assured. The Freemium tools help these folks get started at whatever pace they are going."), "\n", React.createElement(_components.p, null, "I understand that there is without a double a lot of abuse of these tools but it is important to acknowledge that some people will always try to exploit systems free or not. Businesses and we as a community need to decide if the cutting cost by reducing the launch pad(freemium) services is worth the lost relationships and growth from the apps that hit scale."), "\n", React.createElement(_components.p, null, "I think this is a very poor business decision but with many of the larger companies the true faly of these decisions take three to five years to manifest."), "\n", React.createElement(_components.h2, null, "Hosts"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://appliku.com/"
  }, "Appliku")), "\n", React.createElement(_components.p, null, "#django #ease-of-use #database #freemium"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://adaptable.io/"
  }, "Adaptable")), "\n", React.createElement(_components.p, null, "#ease-of-use #freemium #no-private-repo"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://acorn.io/"
  }, "Acore")), "\n", React.createElement(_components.p, null, "#kubernetes #framework"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/amplify/"
  }, "AWS Amplify")), "\n", React.createElement(_components.p, null, "#mega-corp"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://caprover.com/"
  }, "caprover")), "\n", React.createElement(_components.p, null, "#free #open-source #any-language #ease-of-use #cluster-ready"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://pages.cloudflare.com/"
  }, "Cloudflare Pages")), "\n", React.createElement(_components.p, null, "#jamstack #ease-of-use #colabrative #web-analytics #fast"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://www.withcoherence.com/"
  }, "coherence")), "\n", React.createElement(_components.p, null, "#freemium #any-language #ease-of-use #cloud-workspace"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "🤔 Looks very promising"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://coolify.io/"
  }, "Coolify")), "\n", React.createElement(_components.p, null, "#free #open-source #self-hostable"), "\n", React.createElement(_components.p, null, "I tried this out December 2022 and felt it was a little clunky. The interface wasn't as intuitive as I would prefer and the ui felt unpolished."), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://railway.app/"
  }, "RailWay")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "🤔 Looks very promising"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://www.pythonanywhere.com/"
  }, "pythonanywhere")), "\n", React.createElement(_components.p, null, "#free #python"), "\n", React.createElement(_components.h3, null, React.createElement(_components.a, {
    href: "https://surge.sh/"
  }, "Surge")), "\n", React.createElement(_components.p, null, "#jamstack #ease-of-use #fast"), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://dev.to/zevir/the-complete-list-of-heroku-alternatives-to-consider-1l8o"
  }, "The Complete List of Heroku Alternatives to Consider")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
